<template>
  <div class="pb-2">
    <b-card
      no-body
      no-footer
    >
      <b-card-body class="p-0">
        <b-table-simple
          class="mb-0"
          responsive
        >
          <b-thead>
            <b-tr>
              <b-th class="bg-primary text-white f-16">
                {{ $t('table.name') }}
              </b-th>
              <b-th class="bg-primary text-white f-16">
                {{ $t('table.total') }}
              </b-th>
              <b-th
                v-for="wa of weekArray"
                :key="wa.key"
                class="bg-primary text-white text-capitalize text-nowrap"
              >
                {{ wa.text }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="row of rows"
              :key="row.uuid"
              :set="activities = row.dailyActivities.data"
            >
              <b-td>
                <div class="d-flex">
                  <b-avatar
                    v-if="row.avatar"
                    badge
                    :badge-variant="row.isOnline ? 'success' : 'secondary'"
                    badge-offset="-0.2em"
                    :src="getAvatar(row.avatar)"
                    class="ml-1 mt-1"
                    square
                    size="42"
                  />

                  <b-avatar
                    v-else
                    class="ml-1 mt-1"
                    badge
                    :badge-variant="row.isOnline ? 'success' : 'secondary'"
                    badge-offset="-0.2em"
                    :text="getInitials(row.name)"
                    :size="42"
                    :variant="getRandomVariant()"
                    square
                  />
                  <div class="mt-1">
                    <span class="text-nowrap m-0 ml-1 p-0">{{ row.name }}</span>
                    <h5
                      v-if="activities.length > 0"
                      class="pl-1 nickname text-success mt-0"
                    >
                      {{ activities[activities.length - 1].date }}
                    </h5>
                    <h5
                      v-else
                      class="pl-1 nickname text-danger mt-0"
                    >
                      &nbsp; {{ $t('reports.never-tracked-time') }}
                    </h5>
                  </div>
                </div>
              </b-td>
              <b-td>
                {{ getTotalHours(activities) }}
              </b-td>
              <b-td
                v-for="wa of weekArray"
                :key="wa.key"
                :set="day_rec = getDayRec(activities,wa.key)"
              >
                <span @click="SHOW_RECORD(wa.key, row.uuid)">
                  <b-tooltip
                    :target="`${row.uuid}_${wa.key}`"
                    :set="workingTime = getWorkingTime(activities, wa.key)"
                  >
                    <span v-if="workingTime.rawTime > 0 ">
                      <feather-icon icon="ClockIcon" />
                      {{ workingTime.time }}
                      <svg
                        version="1.1"
                        viewBox="0 0 24 24"
                        xml:space="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        style="height: 14px; width: 14px; color: white;  filter: invert(100%)"
                      >
                        <g
                          id="grid_system"
                          class="st0"
                        /><g id="_icons">
                          <path d="M20.9,15.6C20.8,15.2,20.4,15,20,15h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.6l-2.3,2.3c-0.3,0.3-0.4,0.7-0.2,1.1   c0.2,0.4,0.5,0.6,0.9,0.6h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.6l2.3-2.3C21,16.4,21.1,16,20.9,15.6z" />
                          <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10c0.6,0,1-0.4,1-1s-0.4-1-1-1c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8c0,0.6,0.4,1,1,1   s1-0.4,1-1C22,6.5,17.5,2,12,2z" />
                          <path d="M13,8c-0.6,0-1,0.4-1,1v2.6l-2.7,2.7c-0.4,0.4-0.4,1,0,1.4C9.5,15.9,9.7,16,10,16s0.5-0.1,0.7-0.3l3-3   c0.2-0.2,0.3-0.4,0.3-0.7V9C14,8.4,13.6,8,13,8z" /></g></svg>
                      {{ workingTime.idle }}
                    </span>
                    <span v-else>
                      {{ $t('reports.not-worked') }}
                    </span>
                  </b-tooltip>
                  <div :id="`${row.uuid}_${wa.key}`">
                    <b-progress
                      :max="24"
                      variant="success"
                      :class="[day_rec.worked > 0 ? 'progress-bar-primary' : 'progress-bar-danger']"
                      height="1rem"
                    >
                      <b-progress-bar
                        :value="day_rec.worked"
                        class="text-dark"
                      />
                      <b-progress-bar
                        :value="day_rec.idle"
                        variant="secondary"
                        class="text-dark"
                      />
                    </b-progress>
                  </div>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-body>
    </b-card>
    <div>
      <b-pagination
        v-if="paginationData.totalItems > paginationData.perPage"
        :value="1"
        :total-rows="paginationData.totalItems"
        :per-page="paginationData.perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="getUsers"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>
<script>

import {
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BThead,
  BTbody,
  BCard,
  BCardBody,
  BAvatar,
  BProgress,
  BProgressBar,
  VBTooltip,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { setComponentReady } from '@/views/tour/tour'

const moment = require('moment')

export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
    BThead,
    BTbody,
    BTh,
    BCard,
    BCardBody,
    BAvatar,
    BProgress,
    BProgressBar,
    BPagination,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    searchDate: {
      type: String,
      required: true,
    },
    selectedTimezone: {
      type: [String, Object],
      default: null,
    },
    searchTerm: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: [],
      paginationData: {},
    }
  },
  computed: {
    weekInterval() {
      const startDay = this.$store.state.project.company?.weekStartDay ?? 'SUNDAY'
      const weekStart = moment(this.searchDate).day(startDay)
      const weekEnd = moment(weekStart).add('6', 'days')
      return {
        start: weekStart,
        end: weekEnd,
      }
    },
    weekArray() {
      const startOfWeek = this.weekInterval.start
      const endOfWeek = this.weekInterval.end

      const days = []
      let day = startOfWeek

      while (day <= endOfWeek) {
        days.push({
          text: day.format('ddd, MMM D'),
          key: day.format('Y-MM-DD'),
        })
        day = day.clone().add(1, 'd')
      }
      return days
    },
  },
  watch: {
    searchDate: {
      immediate: true,
      handler() {
        this.getUsers()
      },
    },
    selectedTimezone() {
      this.getUsers()
    },
    searchTerm: {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
  },
  methods: {
    getUsers(page = 1) {
      this.$emit('toogleOverlay', true)
      useApollo.activities.getWeeklyTime({
        keyword: this.searchTerm ? this.searchTerm : null,
        page,
        startTime: this.weekInterval.start.format('YYYY-MM-DD 00:00:00'),
        endTime: this.weekInterval.end.format('YYYY-MM-DD 23:59:00'),
        projectId: this.$store.state.project.selectedProject,
      }).then(response => {
        this.rows = response.data.users.data.map(user => ({
          ...user,
        }))
      }).finally(() => {
        this.$emit('toogleOverlay', false)
        this.$nextTick(() => {
          setComponentReady()
        })
      })
    },
    getTotalTime(duration, idle) {
      return duration * (1 - idle)
    },
    getDayRec(arr, day) {
      // const SECOND_RATIO_IN_DAY = 0.000277778
      const findDay = arr.find(d => d.date === day)
      if (findDay) {
        return {
          worked: this.getTotalTime(findDay.duration, findDay.idlePercent) / 3600,
          idle: (findDay.duration * findDay.idlePercent) / 3600,
        }
      }
      return 0
    },
    getWorkingTime(arr, day) {
      const findDay = arr.find(d => d.date === day)
      const time = findDay ? findDay.duration : 0
      return {
        time: this.CONVERT_HM(time),
        rawTime: time,
        idle: findDay ? this.CONVERT_HM(time * findDay.idlePercent) : 0,
      }
      // return {
      //   time,
      //   tooltip: '11',
      // }
    },
    getTotalHours(arr) {
      const total = arr.reduce((i, v) => i + Number(v.duration), 0)
      return this.CONVERT_HM(total)
    },
    assignPaginationData(data) {
      this.paginationData = {
        currentPage: data.current_page,
        perPage: data.per_page,
        totalItems: data.total,
        lastPage: data.last_page,
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .f-16 {
    font-size: 14px;
  }
  .progress {
     border-radius: 0 !important;
    .progress-bar {
      border-radius: 0 !important;
    }
  }
  .fa-face-sleeping:before {
      content: "\e38d";
  }
  .fa-solid, .fas {
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
  }
  .st0{display:none;}
.st1{display:inline;}
.st2{opacity:0.2;fill:none;stroke:#000000;stroke-width:5.000000e-02;stroke-miterlimit:10;}
  /* .progress progress-bar-primary {
    border-radius: 0 !important;
  }
  .progress  */
</style>
